html,
body {
  font-size: 16px;
  color: #dfdfdf;
  background-color: #063647;
  scroll-behavior: smooth;
}

.columns {
  padding: 0px;
  margin: 0px;
}

a {
  color: #00d1b2;
}

a:hover {
  color: #00d1b2;
}

section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.section-1 {
  background: #063647;
}

.section-2 {
  background-color: #052d3d;
}

.section-3 {
  background-color: #0e282e;
}

.section-4 {
  background-color: #063647;
  padding: 0.2rem;
}

a.nav-item {
  color: #dfdfdf;
}

a.nav-item:hover {
  color: #fff;
}

.avatar {
  height: 200px;
  border-radius: 50%;
}

.cloud-text-color {
  color: #ecf0f1;
}

.intro-description {
  padding-top: 1rem;
}

.social-container img {
  max-width: 100%;
  max-height: 175px;
  margin-bottom: 15px;
}

.project-figure {
  margin: 0 auto 1rem auto;
  max-height: 256px;
  max-width: 256px;
}

.project-text {
  text-align: justify;
}

.box {
  background-color: transparent;
  border: 2px solid rgba(7, 59, 79, 0.5);
}

.title {
  color: #fff;
  text-align: left;
  margin-bottom: 2rem;
}

.intro {
  text-align: justify;
  width: 80%;
  margin: 0 auto;
}

.void-background {
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .nav-menu {
    background-color: #063647;
  }

  .nav-toggle:hover {
    background-color: transparent;
  }

  .nav-toggle span {
    background-color: #dfdfdf;
  }

  .nav-toggle span:hover {
    background-color: #fff;
  }
}

* {
  box-sizing: border-box;
}

body {
  background-color: #474e5d;
  font-family: Helvetica, sans-serif;
}
/*
				TIMELINE-CSS
*/
/* The actual timeline (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-container::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: white;
  border: 4px solid #ff9f55;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
  box-sizing: unset;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid white;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent white;
}

/* Add arrows to the right container (pointing left) */
.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 30px;
  border: medium solid white;
  border-width: 10px 10px 10px 0;
  border-color: transparent white transparent transparent;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -16px;
}

/* The actual content */
.timeline-content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 6px;
  color: #052d3d;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media all and (max-width: 600px) {
  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

