/*
Rotation of profile picture
*/
.spin {
  -webkit-animation: spin 2s ease;
  -moz-animation: spin 2s ease;
  animation: spin 2s ease;
}
@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
