/*
 Skills
*/

.skill-name {
  font-weight: bold;
}

.skill-competence {
  margin-left: 5px;
  display: flex;
  flex-direction: row;
}

.skill-description {
  margin-left: 5px;
}

.subbox {
  border: initial;
}
